footer {
    background-color: $grey-color-light;
    .wrapper {
        padding-bottom: 0;
    }
}

.footer-menu {
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $grey-color;
    padding-bottom: 20px;
    flex-wrap: wrap;
    ul,
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .footer-lists {
        width: 20%;
        &--title {
            font-weight: $heading-half-bold;
        }
        li {
            padding-bottom: 10px;
        }
    }
    @include media-query($tablet) {
        justify-content: space-between;
        .footer-lists {
            width: 30%;
        }
    }
    @include media-query($mobile) {
        .footer-lists {
            width: 48%;
            margin-bottom: 20px;
        }
    }
}

.footer-subscribe {
    width: 40%;
    label {
        font-weight: $heading-half-bold;
    }
    .footer-subscribe--input {
        display: flex;
        margin-top: 10px;
        input {
            margin-right: 10px;
            width: 80%;
            border-radius: 5px;
            padding-left: 20px;
            font-size: $base-font-size;
        }
    }
    @include media-query($tablet) {
        width: 100%;
        margin-top: 20px;
        .footer-subscribe--input {
            input {
                width: 70%;
            }
        }
    }
    @include media-query($mobile) {
        .footer-subscribe--input {
            flex-direction: column;
            input,
            button {
                width: 100%;
            }
            input {
                padding: 18px;
                margin-bottom: 10px;
                border: 2px solid $grey-color-light;
            }
        }
    }
}

.footer-text {
    padding-top: 20px;
    .social-media-list {
        display: flex;
        width: 30%;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        a {
            display: block;
        }
    }
    @include media-query($mobile) {
        .social-media-list {
            margin-left: 0;
            width: auto;
        }
    }
}