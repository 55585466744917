main.page-content {
    background-color: $hero-color;
    color: white;

    background-image: url('jochem-raat-s0grRYEDaL4-unsplash.jpg');
    background-size: cover;
    background-position: top;

}

section.wrapper {
    margin-top: 40px;
}

section.why-choose-us {
    .why-choose-us__content {
        display: flex;
        margin-top: 40px;
        img {
            width: 50px;
            height: 50px;
        }
    }
    @include media-query($on-palm) {
        .wrapper-flex {
            flex-direction: column;
        }
        .why-choose-us__content {
            width: 100%;
        }
    }
}
